<template>
    <div class="partial-price-quotes-tables">
        <div class="pq-table-desktop ">
            <table class="pq-table first-and-top w-100 align-self-start ">
                <thead>
                <tr class="bg-gray-lighter">
                    <template v-for="item in getTableShorInfo">
                        <th :style="item?.style" v-if="item.content" class="text-uppercase">{{ item.title }}</th>
                    </template>
                </tr>
                </thead>
                <tbody :style="{backgroundColor : tbodyBackgroundColor}">
                <tr>
                    <template v-for="item in getTableShorInfo">
                        <template v-if="item.content">
                            <td>
                                <template v-if="item?.tooltip">
                                    <template v-if="item.tooltip?.text">

                                        <div class="overflow-text-content position-relative" style="width: 110px">
                                            <div v-tooltip="item.tooltip" class="position-absolute"
                                                 style="width: 100%; inset: 0"></div>
                                            <span>{{ item.content }}</span>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <span v-if="item.tooltip?.list"  v-tooltip="item.tooltip">{{ item.content }}</span>
                                        <span v-else >{{ item.content }}</span>
                                    </template>
                                </template>
                                <template v-else><span :class="item?.class">{{ item.content }}</span></template>
                            </td>
                        </template>
                    </template>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="pq-tables-partials  d-flex gap-3 ">
            <div class="pq-table-item pq-table-mobile">
                <table class="pq-table first align-self-start ">
                    <tbody>
                    <template v-for="item in getTableShorInfo">
                        <tr v-if="item.content">
                            <td class="text-uppercase" :class="item?.class">{{ item.title }}</td>
                            <td>
                                <template v-if="item?.tooltip">

                                    <template v-if="item.tooltip?.text">

                                        <div class="overflow-text-content position-relative" style="width: 110px">
                                            <div v-tooltip="item.tooltip" class="position-absolute"
                                                 style="width: 100%; inset: 0"></div>
                                            <span>{{ item.content }}</span>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <span v-if="item.tooltip?.list"  v-tooltip="item.tooltip">{{ item.content }}</span>
                                        <span v-else >{{ item.content }}</span>
                                    </template>

                                </template>
                                <template v-else>{{ item.content }}</template>

                            </td>
                        </tr>
                    </template>
                    </tbody>
                </table>
            </div>
            <div class="pq-table-item d-flex flex-shrink-0" v-if="getTablePassengerPrice.show">
                <table class="pq-table second align-self-start desktop-pq-table">
                    <tbody>
                    <tr v-for="item in getTablePassengerPrice.table"
                        :style="{ backgroundColor:item?.bgColor}">
                        <td class="text-uppercase" style="width: 100px">{{ item.title }}</td>
                        <template v-if="Array.isArray(item.content)">
                            <td v-for="tdItem in item.content">
                                <template v-if="tdItem?.icon">
                                    <div class="d-flex align-items-center white-space-nowrap">
                                        <acms-v-icon size="20" :name="tdItem.icon"/>
                                        x {{ tdItem.count }}
                                    </div>
                                </template>
                                <template v-else>

                                    <div class="white-space-nowrap">
                                        {{ tdItem }}
                                    </div>
                                </template>
                            </td>
                        </template>
                        <td v-else :colspan="item?.colspan" :class="item?.class">{{ item.content }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="pq-table-item pq-table-block  w-100  d-flex flex-column h-100">
                <PqCodeTable class="mb-4"
                             v-if="data?.pq_flight_legs?.length > 0"
                             :data="{pq_flight_legs:data.pq_flight_legs, flight_segment_routes:data.flight_segment_routes}"
                             :simple-view="simpleView"
                             tableClass="table-border-td w-100"
                             showFromBack
                             :pccOrProgram="data.pcc_or_program == 'Mixed'"
                />
                <div class="d-flex  mt-auto align-items-center ">
                    <div class="d-flex ms-auto gap-2">
                        <template v-if="trackPqsTooltipText">
                            <acms-v-icon size="24" name="tick" :color="iconColor" v-tooltip="trackPqsTooltipText"/>
                        </template>
                        <template v-else-if="!trackPqsTooltipText">
                            <acms-v-icon size="24" name="tick" :color="iconColor" />
                        </template>
                        <acms-v-icon size="24" name="time" v-if="data?.show_fast_time" color="#4B5563CC"/>
                        <acms-v-icon size="24" name="money" v-if="data?.show_min_money" color="#4B5563CC"/>

                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>export default {name: 'PartialPriceQuotesTables'};</script>
<script setup="">
import PqCodeTable from './PartialParsedPqCodeTable.vue';
import {computed} from "vue";
import {useBackendMiddlewarePropsService} from "@services";
const props = defineProps({
    data: Object,
    simpleView: Boolean
})
const iconColor = computed(() => {
    return props.data?.trackPqs?.length > 0 ? '#4CAF51' : '#4B5563CC'; // зеленый если массив не пустой, иначе серый
});
// const trackPqsTooltipText = computed(() => {
//     const trackPqs = props.data?.trackPqs || [];
//     if (trackPqs.length === 0) {
//         return null; // Если массив пуст, тултип не нужен
//     }
//
//     const now = new Date();
//
//     return trackPqs.map(pq => {
//         const createdAt = new Date(pq.created_at);
//         const localTime = createdAt.toLocaleString('en-GB', { day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit' });
//         const diffTime = Math.abs(now - createdAt);
//         const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
//
//         return `Read on ${localTime}, ${diffDays} days ago`;
//     }).join('\n');
// });


const trackPqsTooltipText = computed(() => {
    const created_by = props.data?.created_by;
    const trackPqs = props.data?.trackPqs || [];
    if (trackPqs.length === 0) {
        return null; // Если массив пуст, тултип не нужен
    }

    const now = new Date();

    // Функция для расчёта времени, прошедшего с момента события до текущего момента
    function formatTimeDifference(date) {
        const diff = Math.abs(now - date);
        const minutes = Math.floor(diff / (1000 * 60));
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        if (days > 0) {
            return `${days}d ago`;
        } else if (hours > 0) {
            const remainingMinutes = minutes % 60;
            return `${hours}h ${remainingMinutes}m ago`;
        } else {
            return `${minutes}m ago`;
        }
    }

    // Функция для расчёта времени между отправкой и первым открытием
    function formatTimeDifferenceBetweenDates(startDate, endDate) {
        const diff = Math.abs(endDate - startDate);
        const minutes = Math.floor(diff / (1000 * 60));
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        if (days > 0) {
            return `${days}d ${hours % 24}h ${minutes % 60}m`;
        } else if (hours > 0) {
            return `${hours}h ${minutes % 60}m`;
        } else {
            return `${minutes}m`;
        }
    }

    // Сортировка событий по дате (от самого нового к самому старому)
    const sortedTrackPqs = trackPqs.slice().sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    // Первое событие для отображения информации об открытии
    const firstTrackPq = sortedTrackPqs[sortedTrackPqs.length - 1];
    const firstCreatedAt = firstTrackPq ? new Date(firstTrackPq.created_at) : null;
    let firstOpenInfo = '';

    if (firstCreatedAt) {
        const firstOpenTime = formatTimeDifferenceBetweenDates(firstCreatedAt, now);
        firstOpenInfo = `First open ${firstOpenTime} after it was created.`;
    }

    // Форматируем строки для всех событий, начиная с самого свежего
    const messages = sortedTrackPqs.map((pq, index) => {
        const createdAt = new Date(pq.created_at);

        // Форматируем дату и время создания события
        const formattedDate = createdAt.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit'
        });
        const formattedTime = createdAt.toLocaleTimeString('en-GB', {
            hour: '2-digit',
            minute: '2-digit'
        });

        // Рассчитываем время, прошедшее с момента создания события
        const timeAgo = formatTimeDifference(createdAt);

        return `${sortedTrackPqs.length - index}) ${formattedDate}, ${formattedTime} (${timeAgo})`;
    });

    // Добавляем информацию о первом открытии
    messages.push(firstOpenInfo);

    // Добавляем информацию об отправке
    const firstSentInfo = firstTrackPq ?
        `→ ${firstCreatedAt.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' })}, ${firstCreatedAt.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })} sent by ${created_by ?? 'Creator'}`
        : '';

    messages.push(firstSentInfo);

    return messages.join('\n');
});


const tbodyBackgroundColor = computed(() => {

    const status = props.data.status;

    switch (status) {
        case 'Sent':
            return '#E1EDCF';
        case 'Re-Sent':
            return '#E1EDCF';
        case 'OAF Received':
            return '#f5e99a';
        case 'Sold':
            return '#7dc680';
        case 'Rejected':
            return '#ddbcd7';
        case 'Declined':
            return '#ddbcd7';
        default:
            return '#FFF'; // Цвет по умолчанию, если статус не найден
    }
});
const {backendOptions} = useBackendMiddlewarePropsService()

const formatDateToDDMMMYYWithTime = (dateTimeStr) => {
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    // Разбиваем исходную строку на дату и время
    const [datePart, timePart] = dateTimeStr.split(', ');
    const [month, day , year] = datePart.split('/').map(num => parseInt(num, 10));
    const yearShort = year.toString().substr(-2);
    const monthShort = months[month - 1];
    return `${day < 10 ? '0' + day : day}${monthShort}${yearShort},  ${timePart}`;
};
const getTableShorInfo = computed(() => {

    const {id, hash_id, remark_ext, remark_int, status, created_by, created, role, pcc_or_program, send_logs } = props.data



    return [
        {title: 'STATUS', content: status, style: 'width: 8%'},
        {title: 'PQ ID', content: hash_id ?? id, style: 'width: 8%'},
        {title: 'CREATED', content: formatDateToDDMMMYYWithTime(created)},
        // {title: 'CREATED', content: created},
        {title: 'CREATED BY', content: created_by},
        {title: 'ROLE', content: role?.name ?? 'error role'},
        {
            title: 'Remark Int.',
            content: remark_int ?? 'No remarks',
            tooltip: {text: remark_int?.length > 20 ? remark_int : null}
        },
        {
            title: 'Remark Ext.',
            content: remark_ext ?? 'No remarks',
            tooltip: {text:remark_ext?.length > 20 ? remark_ext : null}
        },
        {title: 'PCC/Program', content: pcc_or_program},
        {title: 'Sent', content: send_logs?.[0]?.send_at,
            tooltip :send_logs?.length > 1 ?  {list:send_logs.map(i=>i?.send_at)}  : null
        },
    ]
})
const getTablePassengerPrice = computed(() => {
    const {pq_passengers} = props.data

    const {adults, children = null, infants = null, profit = null, total = null, sell, total_net} = pq_passengers
    const passengersEntries = Object.entries({adults, children, infants})

    const ptcArr = []
    const netObject = {}
    const saleArr = []
    const totalNetArr = []

    passengersEntries.forEach(([itemKey, itemValue]) => {
        if (itemValue) {
            ptcArr.push({icon: itemKey, count: itemValue?.[0]?.quantity ?? '0'})
            itemValue.forEach((val, index) => {
                if (!netObject[index + 1]) {
                    netObject[index + 1] = []
                }
                netObject[index + 1].push('$' + val?.net ?? '0')
            })
            saleArr.push('$' + sell[itemKey] ?? '0')
            totalNetArr.push('$' + total_net[itemKey] ?? '0')
        }
    }, [])

    const totalAndProfitClass = 'fw-semi-bold' + (ptcArr?.length == 1 ? '' : ' text-center')
    const colspan = ptcArr?.length
    const netArr = Object.entries(netObject)
        .map(([keyNumber, valueArr]) => ({title: 'NET #' + keyNumber, content: valueArr}))
    return {
        count: ptcArr?.length,
        show: ptcArr?.length > 0,
        table: [
            {title: 'PTC', content: ptcArr},
            ...netArr,
            {title: 'TOTAL NET', bgColor: '#FBDECE', content: totalNetArr, colspan, class: totalAndProfitClass},
            {title: 'SALE', content: saleArr},
            {
                title: 'SELLING TOTAL',
                bgColor: '#FBDECE',
                content: total ? '$' + total : null,
                colspan,
                class: totalAndProfitClass
            },
            {
                title: 'MARK-UP',
                bgColor: '#E0EDCF',
                content: profit ? '$' + profit : null,
                colspan,
                class: totalAndProfitClass
            },
        ]
    }
})
</script>
<style scoped lang="scss">
.pq-table-mobile {
    display: none;
}

@media only screen and (max-width: 1500px) {
    .pq-table-mobile {
        display: block;
    }
    .pq-table-desktop {
        display: none;
    }

    .pq-tables-partials {
        flex-wrap: wrap;
    }
    .pq-table-item {
        &:last-child {
            width: 100%;
        }
    }
}
</style>
